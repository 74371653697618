import * as React from "react"
// import { Link } from "gatsby"

import { Container, Row, Col, Form, Button  } from 'react-bootstrap'

import Layout from "../components/layout"
import Seo from "../components/seo"

const SecondPage = () => (
  <Layout>
    <Container className="singleCol">
      <h1 className="text-center my-4 mt-5">Contact Us</h1>
      <h2>Location</h2>
      <p>460 Martin Luther King, Jr. Blvd., Savannah, Georgia 31401</p>
      <h2>Email</h2>
      <p><a href="mailto:vaughnettegoode-walker@ralphmarkgilbertcivilrightsmuseum.org">vaughnettegoode-walker@ralphmarkgilbertcivilrightsmuseum.org</a></p>
      <h2>Phone Number</h2>
      <p>Vaughnette  Goode-Walker, Executive Director<br />(912) 777-6099</p>
      <h2>Leave Us a Message</h2>
      <Form 
                        name="contact"
                        method="post"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit="submit"
                    >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="contact" />
                    <p hidden>
                    <label>
                        Don’t fill this out: <input name="bot-field" />
                    </label>
                    </p>
                     


                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label for="firstName">First Name</Form.Label>
                                    <Form.Control 
                                        required
                                        type="text" name="first-name" id="firstName"
                                     />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                            <Form.Group>
                                    <Form.Label for="lastName">Last Name</Form.Label>
                                    <Form.Control 
                                       type="text" name="last-name"
                                       id="lastName"
                                     />
                            </Form.Group>
                            </Col>
                        </Row>


                            
                        <Form.Group>
                            <Form.Label for="email">Email address</Form.Label>
                            <Form.Control 
                                required
                                type="email" 
                                name="email" 
                                id="email"
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label for="phone">Phone</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="phone" 
                                id="phone"
                            />
                        </Form.Group>
                        
                        <Form.Group>
                            <Form.Label for="help">How can we help you?</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                name="message"
                                rows="3"
                                id="help"
                             />
                        </Form.Group>
                        <Button type="submit">Submit</Button>
                        </Form>
                        <hr />
    </Container>
    <Container className="singleCol">
    <div className="ratio ratio-16x9">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3380.8944323875594!2d-81.10232294916513!3d32.07210392680649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88fb9e422d57c741%3A0x14f1d47e882ad43a!2sRalph%20Mark%20Gilbert%20Civil%20Rights%20Museum!5e0!3m2!1sen!2sus!4v1660317627584!5m2!1sen!2sus"  title="map"></iframe>
        </div>
    </Container>
    
      
  </Layout>
)

export const Head = () => <Seo title="Contact the Museum" />

export default SecondPage
